import * as React from 'react';
import '../../../App.css';
import News from '../../../components/corporate/News';
import { Typography } from '@mui/material';

export default function News1(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <News
      title={'ホームページをリニューアルしました！'}
      date={'2023/07/09(日)'}
      text={<Typography variant="body1" style={{ textAlign: 'left', padding: '10px 0' }}>
          この度、ホームページを全面リニューアルいたしました。<br />
          今回のリニューアルは、皆様に見やすく、弊社のことを分かりやすくお伝えできるホームページに改善させて頂きました。<br />
          採用に関しましては、より情報が探しやすいようデザインを全面的に刷新しました。<br />
          今後とも何卒よろしくお願い申し上げます。
      </Typography>}
    ></News>
  );
}