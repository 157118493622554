import { autocompleteClasses, Box, Typography,Grid } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';

export default function ContentsBoxWithTagAndImage(children) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{
        width: autocompleteClasses,
        height: autocompleteClasses,
        backgroundColor: 'inherit',
        whiteSpace: 'pre-line',
        alignItems:"right"
      }}>
        <Box sx={{flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item sm={6} md={6} >
              <Box sx={{padding: '10% 0 0 0'}}>
                <img src={children.imageSrc} width="90%"  alt=""></img>
              </Box>
          </Grid>
          <Grid item sm={6} md={6}>
            <Box style={{width:{xs:"60%",sm:"70%",md:"100%",xl:"100%"},padding: '18% 0 0 10%', textAlign: 'left'}}>
              <Typography variant="h2"sx = {{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" }}} style={{ padding: '0px 0 3% 0' ,color:`${colorTheme.palette.navy.main}`}} fontWeight={'bold'}>{children.title}</Typography>
              <Typography variant="h2"sx = {{fontSize: { xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" }}} style={{ padding: '0px 0px 3% 0' }} >{children.text}</Typography>
              <Typography variant="h2"sx = {{fontSize:{ xs: "12px", sm: "15px", md: "20px",lg:"25px", xl: "1.2vw" }}} style={{ padding: '0px 7% 5% 0'}} fontWeight={"weight "}>{children.subtext}</Typography>
            </Box>
          </Grid>
        </Grid>
        </Box>
      </Box>
    </ThemeProvider>

  )
}
