import { autocompleteClasses, Box, Typography } from '@mui/material';
import * as React from 'react';
import { colorTheme } from '../../const';
import WorkLifeBalance2Top from '../../image/interviews/work_life_balance2_top.png'
import ContentsBox from '../../components/ContentsBox';
import Image from '../../components/Image';
import ContentsBoxWithSideLine from '../../components/ContentsBoxWithSideLine';
import Tag from '../../components/Tag';
import BenefitsTop from '../../image/benefits/benefits_top.png';
import BenefitsText1 from '../../image/benefits/benefits_text1.png';
import BenefitsText2 from '../../image/benefits/benefits_text2.png';
import { useMediaQuery } from 'react-responsive';
import { ThemeProvider } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MuiButton from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ScrollToTopIcon from '../../components/ScrollToTopIcon';
import ButtonCircle from "../../components/ButtonCircle";

const Benefits = (props) => {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });

  const topMessageTitle = "コラボテクノの\n福利厚生"
  const topMessage = "コラボテクノには、産休育休はもちろん、\nさまざまな社内制度を通じて、\n社員のキャリアや人生設計を応援しています。"
  const pcTopMessage = "コラボテクノには、\n産休育休はもちろん、\nさまざまな社内制度を通じて、\n社員のキャリアや人生設計を\n応援しています。"
  const bodyMessageTitle1 = "産前・産後休暇／育児休暇"
  const bodyMessage1 = "女性社員の育児休暇はもちろん、\n男性社員の育児休暇も積極的に\n推奨しています。"
  const bodyMessageTitle2 = "資格取得支援"
  const bodyMessage2 = "資格取得のための受験料や取得した際の\nインセンティブなどを提供し、\nエンジニアの成長を支援しています。"
  const bodyMessageTitle3 = "フリーワーク制度"
  const bodyMessageSubTitle3 = "（社内副業）"
  const bodyMessage3 = "社内プロジェクトや新規事業の参画にあたって、\n本来の給与とは別に、\n副業として給料をお支払いします。"
  const bodyMessageTitle4 = "独立起業支援"
  const bodyMessage4 = "独立や起業を目指す社員を応援します。\n資本金や設立の支援、事業計画の立て方など、\n経営面のアドバイスを行います。"
  const footerTitle = "育休制度を利用した\n社員の声"
  const pcFooterTitle = "育休制度を利用した社員の声"
  // 以下footerMessageはPC用だと改行がうまく機能しなかったのでベタがき
  const footerMessage = "コラボテクノの育休制度について、\n実際に利用した社員にインタビューしました。"
  const footerEmName = "山本 高志 システムエンジニア 2016入社"
  // 以下footerMessageTitleはPC用だと改行がうまく機能しなかったのでベタがき
  const footerMessageTitle = "まるまる1ヶ月間、\n”育業”の現場にシフト。"
  const tag1 = "Benefits"
  const tag2 = "社内制度"
  const tag3 = "INTERVIEW"

  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  // スマホ画面用
  if (isMobileScreen) {
    function displayImg(img) {
      return (
        <Box sx={{ padding: '16px 0 16px 0' }}>
          <Image imageSrc={img} width="84%" height="80%" alt="" radius="10px"></Image>
        </Box>
      )
    }
    return (
      <Box className='App'>
        <Box sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}>
          <img src={process.env.PUBLIC_URL + BenefitsTop} width='100%' alt='トップ画像'></img>
          <Box sx={{
            width: autocompleteClasses,
            height: autocompleteClasses,
            position: 'relative',
            whiteSpace: 'pre-line',
            paddingTop: '54px'
          }}>
          </Box>
          <Box sx={{
            width: autocompleteClasses,
            height: autocompleteClasses,
            position: 'relative',
            whiteSpace: 'pre-line',
          }}>
            <Tag tagStyle={'left'} tagText={tag1} />
            <br />
            <Box sx={{ padding: '30px 45px 10px 40px', textAlign: 'left' }}>
              <Typography variant="h5" sx={{ padding: '0 0 15px 0', textAlign: 'left', fontSize: '22px', color: colorTheme.palette.navy.main, letterSpacing: '1.5px'}} fontWeight={'bold'}>{topMessageTitle}</Typography>
              <Typography variant="h9" sx={{ padding: '0px', textAlign: 'left', fontSize: '13px' }}>{topMessage}</Typography><br />
            </Box>
          </Box>
          <br />
          <Box sx={{ backgroundColor: 'white' }}>
            <br />
            <br />
            <Tag tagStyle={'right'} tagText={tag2} />
            <br />
            <br />
            {displayImg(BenefitsText1)}
            <Box sx={{ padding: '15px 0px' }}>
              <ContentsBoxWithSideLine
                title={bodyMessageTitle1}
                text={bodyMessage1} />
              <ContentsBoxWithSideLine
                title={bodyMessageTitle2}
                text={bodyMessage2} />
              <ContentsBoxWithSideLine
                title={bodyMessageTitle3} subtitle={bodyMessageSubTitle3}
                text={bodyMessage3} />
              <ContentsBoxWithSideLine
                title={bodyMessageTitle4}
                text={bodyMessage4} />
            </Box>
            {displayImg(BenefitsText2)}
            <br />
            <br />
          </Box>
          <br />
          <ContentsBox
            title={footerTitle}
            titleStyle={{ fontSize: '5.5vw', letterSpacing: '1.5px'}}
            text={footerMessage}
            textStyle={{ fontSize: '3vw', letterSpacing: '1px'}}
          />
          {displayImg(WorkLifeBalance2Top)}
          <Box sx={{
            width: autocompleteClasses,
            height: autocompleteClasses,
            backgroundColor: 'inherit',
            position: 'relative',
            whiteSpace: 'pre-line'
          }}>
            <Box style={{
              padding: '0px 45px',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}>
              <Typography fontSize={"2vw"} style={{ padding: '0px 0px 5px 0px' }}>
                {footerEmName}
              </Typography>
              <Typography fontSize={"4vw"} fontWeight={'bold'} style={{ padding: '0px 0px 0px 0px' }}>
                {footerMessageTitle}
              </Typography>
            </Box>
          </Box>
          {/* </Link> */}
          <Box sx={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <ButtonCircle
              url="/recruit/member/work-life-interview2"
              text="続きを読む"
            ></ButtonCircle>
          </Box>
          <br />
        </Box>
        <ScrollToTopIcon />
      </Box>
    );
    // 以下PC画面用
  } else {
    return (
      <Box className='App'>
        <Box sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}>
          <Box >
            <ThemeProvider theme={colorTheme}>
              <div style={{ position: 'relative', width: '100%', padding: '0px 10px 45% 0px' }}>
                <img src={process.env.PUBLIC_URL + BenefitsTop} width='70%' height='100%' alt='トップ画像' style={{ position: 'absolute', right: 0 }}></img>
                <div style={{
                  display: 'inline-block',
                  width: '35%',
                  minWidth: '300px',
                  height: '80%',
                  backgroundColor: 'white',
                  position: 'absolute',
                  left: 0,
                  top: 180,
                  borderRadius: '0px 20px 20px 0px',
                  whiteSpace: 'pre-line',
                  margin: '-140px 0 -0px 0',
                }}>
                  <div style={{padding: '10% 0', marginTop: '3%'}}>
                    <Tag tagStyle={'left'} tagText={tag1}/>
                  </div>
                  <Box style={{
                    textAlign: 'left',
                    position: 'absolute',
                    top: '55%',
                    left: '45%',
                    transform: 'translate(-50%, -50%)'
                  }}>
                    <Typography variant="h5" sx={{ padding: '0 0 5% 0', textAlign: 'left', fontWeight: 'bold', fontSize: '2.3vw', lineHeight: '150%', color: colorTheme.palette.navy.main }}>
                      {topMessageTitle}
                    </Typography>
                    <Typography variant="h9" sx={{ padding: '40px 0px', textAlign: 'left', fontSize: '1.3vw', lineHeight: '150%' }}>
                      {pcTopMessage}
                    </Typography>
                  </Box>
                </div>
              </div>
            </ThemeProvider>
          </Box>
          <Box sx={{ backgroundColor: 'white' ,padding: '5% 0'}}>
            <div>
              <Tag width={'50%'} tagText={tag2} />
            </div>
            <div style={{ display: 'flex',  justifyContent: 'space-between', margin: '5% 7% 5% 7%'}}>
              <ContentsBoxWithSideLine
                title={bodyMessageTitle1}
                text={bodyMessage1} />
              <ContentsBoxWithSideLine
                title={bodyMessageTitle3} subtitle={bodyMessageSubTitle3}
                text={bodyMessage3} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 7% 5% 7%'}}>
              <ContentsBoxWithSideLine
                title={bodyMessageTitle2}
                text={bodyMessage2} />
              <ContentsBoxWithSideLine
                title={bodyMessageTitle4}
                text={bodyMessage4} />
            </div>
            <div style={{ display: 'flex' ,justifyContent: 'space-between', margin: '0 7%' }}>
              <img src={process.env.PUBLIC_URL + BenefitsText1} width='45%' alt='画像' style={{borderRadius: '20px'}}></img>
              <img src={process.env.PUBLIC_URL + BenefitsText2} width='45%' alt='画像' style={{borderRadius: '20px'}}></img>
            </div>
          </Box>
          <Typography fontSize={"2.5vw"} style={{ textAlign: 'left', padding: '4% 0 2% 7%', color: colorTheme.palette.navy.main }} fontWeight={'bold'}>
            {pcFooterTitle}
          </Typography>
          <div style={{ position: 'relative', padding: '10px 10px 30% 0px' }}>
            <img src={process.env.PUBLIC_URL + WorkLifeBalance2Top} width='40%' alt='画像' style={{ position: 'absolute', borderRadius: '25px', left: '7%' }}></img>
            <Box sx={{ position: "absolute", padding: "2% 0 0 7%" }}>
              <Tag tagStyle={'left'} tagText={tag3} />
            </Box>
            <Box style={{
              padding: '35px 55px 0px 55px',
              textAlign: 'left'
            }}>
              <div style={{ position: 'absolute', left: '55%', padding: '0px 0px 15px 0px', textAlign: 'left' }}>
                <Typography style={{ padding: '0px 0px 15px 0px' }} fontSize={"2.3vw"} fontWeight={'bold'} letterSpacing={'2px'}>
                  {"まるまる1ヶ月間、"}<br />{"”育業”の現場にシフト。"}
                </Typography>
                <Typography style={{ padding: '0px 0px 15px 0px' }} fontSize={"1.8vw"} fontWeight={'bold'} >
                  {footerEmName}
                </Typography>
                <Typography style={{ padding: '0px 0px 15px 0px' }} fontSize={"1.3vw"} >
                  {"コラボテクノの育休制度について、"}<br />{"実際に利用した社員にインタビューしました。"}
                </Typography>
                <Box sx={{ paddingTop: "5%", paddingBottom: "5%" }}>
                  <ThemeProvider theme={colorTheme}>
                    <Link to="/recruit/member/work-life-interview2">
                      <MuiButton color='blackGray' sx={{
                        border: 'solid 2px',
                        borderRadius: '9999px',
                        fontSize: '15px',
                        letterSpacing: '0.1em',
                        width: '100%',
                        padding: '10px 30px 10px 6vw',
                        justifyContent: 'space-between'
                      }}>
                        <b>続きを読む</b>
                        <ArrowForwardIosIcon sx={{ height: '15px' }} />
                      </MuiButton>
                    </Link>
                  </ThemeProvider>
                </Box>
              </div>
            </Box>
          </div>
          <br />
        </Box>
        <ScrollToTopIcon />
      </Box>
    );
  }
};
export default Benefits;