import { Box, Typography } from '@mui/material';
import * as React from 'react';
import Tag from '../../components/Tag';
import Footer from './footer';
import { Link } from 'react-router-dom';
import ScrollToTopIcon from '../../components/ScrollToTopIcon';

export default function PrivacyPolicy(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <body>
      <div class="wrapper clearfix">
        <article class="onethird">
          <div class="spwrapper">
            <div class="companybox clearfix">
              <h3 class="consen">個人情報保護方針（<Link to="/security" style={{textDecoration: 'underline', color: 'inherit'}}>個人情報の取り扱いについて</Link>）</h3>
                <Box className="company-privacy-policy">
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"個人情報保護方針"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>コラボテクノ株式会社は、当社の業務である様々なシステムソリューションの提供において取り扱う個人情報を、適切に保護することの社会的使命を十分に認識した上で、社会的要請の変化、経営環境の変動等について個人情報保護の仕組みを継続的に改善し、個人情報の保護に全社を挙げて取り組むことを宣言致します。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"個人情報の適用範囲"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>個人情報は当社の行う以下の情報サービス関連業務<br />１．コンピュータシステムの調査・企画・提案・設計・開発・運用<br />２．コンピュータ関連技術サポート<br />を正当に遂行する上で必要な範囲に限定して、取得・利用・提供します。<br />なお、これには業務上における受託個人情報も含みます。<br />目的外の利用は行いません。<br />目的外の利用を行わないための措置を講じます。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"個人情報セキュリティ体制の向上"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>個人情報への不正アクセスまたはその滅失・き損及び漏洩等のリスクに対しては、合理的な安全対策及び予防・是正処置を講じ、事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"法令・規範の遵守"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"苦情・相談の対応"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>当社の個人情報取扱いに関する苦情・相談・問合せについては苦情相談窓口を常設し、適切かつ迅速に対応します。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"個人情報保護マネジメントシステムの改善"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>社内の日常的な点検や、当社を取り巻く環境の変化、社会的な要求などを踏まえ、適時・適切にマネジメントシステムを見直しその改善を継続的に推進します。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div>
                  <Box className="" sx={{
                    float: 'left',
                    display: 'flex',
                    padding: '15px 0px 15px 5px',
                    textAlign: 'left',
                    fontSize: '12px'
                  }}>
                    制定日：2017年1月1日<br />最終改定日：
                  </Box>
                  <Box sx={{
                    float: 'right',
                    display: 'flex',
                    padding: '0 4.5vw',
                    textAlign: 'right',
                    fontSize: "12px"
                  }}>
                    【個人情報保護方針に関するお問合せ先】<br />個人情報保護　苦情・相談窓口<br />メール partner@colla-tech.co.jp<br />コラボテクノ株式会社<br />代表取締役　吉田　光哉
                  </Box>
                  </div>
                </Box>
            </div>
          </div>
        </article>
      </div>
      <ScrollToTopIcon />
      <Footer />
    </body>
  )
}