import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import NewEmployee2Top from '../../../image/interviews/new_employee2_top.png'
import NewEmployee2Text1 from '../../../image/interviews/new_employee2_text1.png'
import NewEmployee2Text2 from '../../../image/interviews/new_employee2_text2.png'
import NewEmployee2Text3 from '../../../image/interviews/new_employee2_text3.png'
import { useMediaQuery } from 'react-responsive';

export default function NewEmployeeInterview2(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  return (
    <PersonInterview
      nextUrl={"/recruit/member/interview3"}
      tagStyle={{ float: 'left' }}
      tagText={"INTERVIEW"}
      name={"佐々木 誠"}
      entryYear={"2022"}
      title={isMobileScreen ? "好きなゲームづくりから、\n世の中を動かすものづくりへ。" : "好きな\nゲームづくりから、\n世の中を動かす\nものづくりへ。"}
      imageSrcTop={NewEmployee2Top}
      text1={"大学は理系ですが、ITとはあまり関係ない専攻でした。なので、エンジニアを志向したのは、もともとゲームが好きで、本を見ながらパズルゲームをつくったりして遊んでいた経験からです。壮大な3DRPGづくりにもチャレンジして途中で挫折しましたが、プログラミング自体は楽しかったこともあり、今エンジニア職に就いたのは自然な流れかなと感じています。他の職種はまったく検討しませんでした。\n\n就活する前は漠然とゲーム会社やゲーム系のエンジニアがいいなと思っていましたが、就活するときには、ゲームづくりは趣味でもできるし、ゲームに縛られる意味は大してないなと思い直しました。それよりも、仕事としては幅広く、世の中を動かすものづくりの方が楽しそうに感じてきて、システムエンジニアへと舵を切りました。"}
      imageSrcText1={NewEmployee2Text1}
      text2Head={"入社の決め手は、未経験者を\n育て上げる意志と環境があること。"}
      text2Body={"プログラミングはゲームづくりでかじっていたものの、かなり初歩的なもので未経験同然だし、またIT業界にも詳しくなかったので、しっかりした研修や実践的なスキル養成が望める企業に絞って就活しました。企業規模を問わなかったこともあってか、わりと早い段階でいくつか内定をいただき、最終的にコラボテクノを選びました。内定後に先輩社員と話す機会があって、自分が入った時のイメージがつかみやすかったことと、若い会社で、会社と一緒に自分もしっかり成長できそうな環境であること、いろんなことにすぐ挑戦できそうな環境であることが決め手でした。\n\n実際システムエンジニアという職業については、ネット上には悪い情報ばかり転がっていて、不安に思うことがなかったわけではありません。でも、いざ業界に飛び込んでみると、コラボテクノはしっかりとした研修と体制が整っていて、さらにOJTで常駐している企業の現場もいい人たちばかりで、今となっては本当にそんな悪い環境があるの？っていうイメージです。想像を超えてホワイトでした（笑）"}
      imageSrcText2={NewEmployee2Text2}
      text3Head={"コミュニケーションを大切に、\n次期新卒の良いお手本をめざして。"}
      text3Body={"入社してから3ヶ月の新人研修で基礎を学び、今は新卒の同期2人と一緒にOJTに就いています。実際の業務に関連するプログラムをこなす中では、コミュニケーションの大切さを実感しました。わからないところがあったときに、先輩やチームのメンバーに、気軽に、そして的確に話せないと、ドツボにハマって抜け出せずに結局、仕事ができなくなってしまいます。また、会話することで、漠然としていたことが明確になることもあるなと感じています。なので、積極的にコミュニケーションすることを意識するようになりました。\n\nエンジニアとして一歩を踏み出したところなので、今後のことはあまり考えていませんが、これから入ってくる新卒・未経験の後輩を引っ張っていけるよう、技術やマネジメントスキルをしっかり身につけて、良いお手本になれればいいなと思っています。"}
      imageSrcText3={NewEmployee2Text3} />
  );
}