import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import AccordionRequirementsEntry from "../components/AccordionRequirementsEntry";
import AccordionRequirementsNewGraduates from "../components/AccordionRequirementsNewGraduates";
import ScrollToTopIcon from './../components/ScrollToTopIcon';
import { ThemeProvider } from "@mui/material/styles";
import EntryBotton from "../components/EntryButton";

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>

        <Typography id="new-graduates" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
          新卒採用
        </Typography>

        <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsNewGraduates
              category="新卒採用"
              jobContent="Webシステム開発"
              Menutext="システムエンジニア"
              Menudetail={[]}
              condition={["■素直に物事を捉えれるれる方", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方"]}
              recruitingCount="若干数"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime="完全土日祝休み 9:30 ～ 18:30"
              salary="年俸　2,500,000円　～　3,600,000円"
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/xXqKTGQ56HyhEs8h9"
            ></AccordionRequirementsNewGraduates>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <ThemeProvider theme={colorTheme}>
              <Box
                sx={{
                  padding: {xs: "10% 5%", sm: "10% 5%", md: "5%"},
                  width: { xs: "80%", sm: "80%", md: "75%", lg: "65%", xl: "45%" },
                  margin: "0 auto",
                }}
              >
                <Box
                  sx={{ fontSize:{ xs: "3.5vw", sm: "3.5vw", md: "3vw", lg:"1.5vw", xl: "1.5vw" }, padding: { xs: "3% 2%", lg: "2% 0" } }}
                  style={{
                    padding: "3% 2%",
                    borderWidth: "1",
                    border: `0.4vw solid ${colorTheme.palette.turquoiseBlue.main}`,
                    backgroundColor: "white",
                    color: `${colorTheme.palette.turquoiseBlue.main}`,
                    width: "50%",
                    textAlign: "",
                    whiteSpace: "pre-warp",
                  }}
                >
                  新卒採用
                </Box>
                <Typography
                  sx={{fontSize:{ xs: "5vw", sm: "5vw", md: "3vw", lg:"2vw", xl: "2vw" }}}
                  style={{ padding: "5% 0px 3% 0px" }}
                  fontWeight={"bold"}
                  textAlign={"left"}
                >
                  25卒向け選考直結型イベント<br />
                  <br />
                  <Typography sx={{ fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1.2vw", xl: "1vw" }, textAlign: 'left'  }}>
                    <div style={{ fontWeight:"bold" }}>＜25卒早期選考で、【プログラミング体験】にご招待！＞</div>
                    コラボテクノの若手エンジニアと一緒にプログラミング体験をしよう！<br />
                    <br />
                    ゲーム「テトリス」作りを体験しよう<br />
                    ・実施日程：<br />
                    　・10月14日（土）、28日（土）<br />
                    　・11月4日（土）、18日（土）<br />
                    　・12月2日（土）、16日（土）<br />
                    ・実施時間：<br />
                    　13:00～16:30<br />
                    　13:00～15:30　プログラミング体験<br />
                    　15:30～16:30　コラボテクノの会社説明およびエンジニアから現場説明<br />
                    ・場所：東京都港区新橋3-11-8 オーイズミ新橋第2ビル 901号<br />
                    ・募集人員：最大4名／1回<br />
                    ・持ち物：ご自身のPCを持参ください<br />
                    ・服装：自由
                  </Typography>
                </Typography>
                <div>
                  <Box sx={{ marginTop: "10%" }}>
                    <EntryBotton text={"申し込む"} url="https://forms.gle/wEdw6jknjG7kUv85A"></EntryBotton>
                  </Box>
                </div>
              </Box>
            </ThemeProvider>
          </Box>

        <Box sx={{ paddingBottom: "10%" }}>

          <Typography id="mid-career" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
            中途採用
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["経験・スキルに応じてプロジェクトに配属", "プロジェクト配属における大原則", "１．Webシステム開発のプロジェクト", "２．Javaをアプリケーション言語にしたプロジェクト", "３．目標は、プロジェクトリーダー・マネージャー"]}
              jobContent2={["開発プロジェクトの紹介", "■通信会社向け　顧客対応システム開発（Java）", "→アジャイル", "■農業事業者向け　クラウドサービス導入支援(PHP) 　", "→顧客との要件定義づめから保守・運用まで ",
                "■損保会社向け　共通基盤開発 （Java）　", "→Javaを用いた設計から開発標準の策定を行う上流案件", " ■コミック本ECサイト構築支援(PHP)", "→バックエンドの決裁部分の構築"]}
              Menutext="システムエンジニア（Web・オープン系）"
              Menudetail={[]}
              condition={["■なんらかのオープン言語での開発2年以上", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えられる方", "■PM,PL思考の方"]}
              recruitingCount="事業拡大のため、2-5名位まで/年"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime="完全土日祝休み 9:30 ～ 18:30"
              salary="年俸　5,000,000円　～　8,500,000円(入社当初のスキルによって変動あり)"
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/b427nYKMfaaqUPFX6"
            ></AccordionRequirementsEntry>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["経験・スキルに応じてプロジェクトに配属", "プロジェクト配属における大原則", "１．Webシステム開発のプロジェクト", "２．Javaをアプリケーション言語にしたプロジェクト", "３．目標は、プロジェクトリーダー・マネージャー"]}
              jobContent2={["開発プロジェクトの紹介", "■通信会社向け　顧客対応システム開発（Java）", "→アジャイル", "■農業事業者向け　クラウドサービス導入支援(PHP) 　", "→顧客との要件定義づめから保守・運用まで ",
                "■損保会社向け　共通基盤開発 （Java）　", "→Javaを用いた設計から開発標準の策定を行う上流案件", " ■コミック本ECサイト構築支援(PHP)", "→バックエンドの決裁部分の構築"]}
              Menutext="経験浅　システムエンジニア（Web・オープン系）"
              Menudetail={[]}
              condition={["■何かしらのオープン言語半年〜2年未満", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えれるれる方"]}
              recruitingCount="2-5名位まで/年"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime="完全土日祝休み 9:30 ～ 18:30"
              salary="年俸　3,000,000円　～　4,000,000円"
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/b427nYKMfaaqUPFX6"
            ></AccordionRequirementsEntry>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["■通信会社向け店頭顧客対応の開発", "■農業事業者向けクラウドサービス導入支援", "■荷物追跡サービス開発", "■アパレル会社向けEコマース保守開発"]}
              jobContent2={[]}
              Menutext="インフラエンジニア"
              Menudetail={[]}
              condition={["■何かしらのクラウド基盤・インフラ構築支援の経験2年以上", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えれるれる方"]}
              recruitingCount="2-3名"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime="完全土日祝休み 9:30 ～ 18:30"
              salary="年俸　 5,000,000円　～　8,000,000円"
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/b427nYKMfaaqUPFX6"
            ></AccordionRequirementsEntry>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            {/* <AccordionRequirementsEntry
              category="ライブ配信"
              Menutext="医療系ライブ配信ディレクター"
              Menudetail="外資・内資の大手製薬メーカーを中心に医師向け講演会などのインターネットライブ中継のディレクション業務をお任せします。"
              period="冬季インターンシップ（12月～2月）"
              occupation="システムエンジニア"
              content="IT業界のいろは"
              condition="将来Web業界（ディレクター、プログラマー、システムエンジニア等）で働きたい強い希望があること"
              belongings={[
                "筆記用具",
                "愛用のノートパソコンがあるならば持参OK",
              ]}
              selection="応募順に選考いたします。定員に達し次第、締切ります。"
              url="https://forms.gle/D1G9KdhM9feAVZJe7"
            ></AccordionRequirementsEntry>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "10%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="その他"
              Menutext="インターシップ"
              Menudetail="冬季(12～2月)インターシップの募集です。IT業界の知識やシステムエンジニアリングの業務を学べます。"
              period="冬季インターンシップ（12月～2月）"
              occupation="システムエンジニア"
              content="IT業界のいろは"
              condition="将来Web業界（ディレクター、プログラマー、システムエンジニア等）で働きたい強い希望があること"
              belongings={[
                "筆記用具",
                "愛用のノートパソコンがあるならば持参OK",
              ]}
              selection="応募順に選考いたします。定員に達し次第、締切ります。"
              url="https://forms.gle/Sh1pEQ1GFUK3mU8QA"
            ></AccordionRequirementsEntry> */}
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
