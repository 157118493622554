import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import JobChange3Top from '../../../image/interviews/job_change3_top.png'
import JobChange3Text1 from '../../../image/interviews/job_change3_text1.png'
import JobChange3Text2 from '../../../image/interviews/job_change3_text2.png'
import JobChange3Text3 from '../../../image/interviews/job_change3_text3.png'
import { useMediaQuery } from 'react-responsive';

export default function JobChangeInterview2(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  return (
    <PersonInterview
      nextUrl={"/recruit/member/work-life-interview1"}
      tagStyle={{ float: 'left' }}
      tagText={"INTERVIEW"}
      name={"野原 僚香"}
      entryYear={"2021"}
      title={isMobileScreen ? "システムをつくるのに、\n性別は関係ない。" : "システム\nをつくるのに、\n性別は関係ない。"}
      imageSrcTop={JobChange3Top}
      text1={"エンジニアになって2年、システム構築の現場に勤めましたが、女性はチームに普通にいましたし、産休明けて以前と変わらず復帰した方も見ました。男性も当たり前のように育休を取りますし。だから今、こうして聞かれるまで意識してなかったんですけど、改めて性別が関係ない職業なんだなと実感しました。待遇に差はありませんし、仕事に必要なスキルや知識を身につけて、それを日々更新していく気持ちがあれば、関係なくやっていけると思います。\n\nただ、エンジニアって意外とコードを書く機会って少なくて、仕様作成とか、会議とかでコミュニケーションしていく時間の方が長いんです。どこまで自分が担当するかとか、責任の範囲だったりとか、コードって10行くらい書けばできちゃうんですけど、その仕様を詰めていくこの話し合いはすごく長くて、転職当初はギャップを感じました。作ったら作ったでテストにもすごく時間がかかるし、全体の中でコードを書く時間ってほんと一瞬なんだなって。なので、人とコミュニケーションする力はそれなりに必要かもしれません。"}
      imageSrcText1={JobChange3Text1}
      text2Head={"今も、その先も、\n普通に活躍できるエンジニアに。"}
      text2Body={"今は2年ほど勤務したシステム構築の現場を離れて、次の現場への配属を調整してもらっています。また新しいスキルや知識を覚えたいと思ったので。現場をちょくちょく変えて心機一転できるのも、SESという働き方のいいところかなと思います。リモート勤務なので、現場を変えると通勤が長くなるとか、複雑になるとか、そういう心配もないですし。この先、実家の方で何かあって、戻ることになったとしても、仕事を続けられるのは安心ですね。\n\n5年後プロジェクトリーダーになりたいとか、10年以内に独立起業したいとか、そういう野望は持っていません（笑）。この会社にはそれを叶えるルートが確かにありますけど、私はあまり先のことを考えるより、今を大切に。常に普通のパフォーマンスを続けていくというのも、きっと難しいことだと思うんです。技術はいろいろ目まぐるしく変わっていくので、ちゃんとその情報をキャッチアップしながら、普通に活躍していく、ちゃんとできる、そんな自分でありたいですね、ずっと。"}
      imageSrcText2={JobChange3Text2}
      text3Head={"働き続けるために、\nなくならない仕事を選ぶ。"}
      text3Body={"エンジニアになる前は、地方のプロパンガスの会社に5年ほど勤めていました。新しく立ち上げた営業所に配属されたので、結構忙しくて、残業も休日出勤も当たり前でしたが、充実感もあり、また職場の同僚や先輩もいい方たちで仲良く、ほんとに何の不満もありませんでした。でも、一般事務職の仕事は、接客や電話対応の顧客管理などで、自分の将来に不安を感じてしまって。この先、社内システムが自動入力するようになったら、私の出番はあるのかな、と。こういうシステムを作る側になった方が将来性あるんじゃないかと感じて、思い切って転職しました。\n\nプログラミングスクールに通ったり、ITパスポートを取得したりして、28歳で上京。実は東京に行きたいという想いも、密かな転職理由だったりします（笑）。コロナでせっかくの東京を満喫できていないのは誤算でしたけど。つまり、畑違いの仕事からでも、年齢がいくつであろうと、性別関係なく、エンジニアにはなれます。大それた野心も要りません。私がいい例です。これからめざす人には、安心してこっちにきてほしいと思いますね。"}
      imageSrcText3={JobChange3Text3} />
  );
}