import * as React from 'react';
import { Typography } from '@mui/material';

export default function ServiceImage(props) {
    return (
        <div sx={{ width: { xs: "110px", sm: "6vw", md: "6vw" } }} style={{ textAlign: "center", padding: '1%' }}>
            <img src={process.env.PUBLIC_URL+props.imageSrc} alt={props.alt} />
            <Typography fontSize={"12px"} align={"center"} style={{whiteSpace: 'pre-line'}}>{props.text}</Typography>
        </div>
    );
  }
