import * as React from 'react';
import Footer from './footer';
import GoogleMap from '../../components/GoogleMap';
import AdjustIcon from '@mui/icons-material/Adjust';
import LinkIcon from '@mui/icons-material/Link';
import ScrollToTopIcon from '../../components/ScrollToTopIcon';

export default function AboutUs(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
      <body>
        <div class="wrapper clearfix">
          <article class="onethird">
            <div class="spwrapper">
              <div id="sec1" class="companybox clearfix">
                <h3 class="consen">会社概要</h3>
                <table class="company">
                  <tbody>
                    <tr>
                      <th>商号</th>
                      <td>
                      <p>コラボテクノ株式会社</p>
                      <p>COLLABO TECHNO Co., Ltd.</p></td>
                    </tr>
                    <tr>
                      <th>所在地</th>
                      <td>
                        <p><b>【新橋オフィス】</b><br /> 〒105-0004 東京都港区新橋3-11-8 <br /> オーイズミ新橋第2ビル 901号</p>
                        <p><b>【本店】（登記所在地）</b><br /> 〒104-0061 東京都中央区銀座6-6-1 銀座風月堂ビル5階</p>
                      </td>
                    </tr>
                    <tr>
                      <th>設立</th>
                      <td>2016年4月</td>
                    </tr>
                    <tr>
                      <th>資本金</th>
                      <td>10,000,000円</td>
                    </tr>
                    <tr>
                      <th>役員</th>
                      <td>
                        <p>代表取締役　吉田　光哉</p>
                        <p>取締役　　　軍司　穗</p>
                      </td>
                    </tr>
                    <tr>
                      <th>顧問弁護士</th>
                      <td>
                        <p>新幸総合法律事務所</p>
                      </td>
                    </tr>
                    <tr>
                      <th>顧問社労士</th>
                      <td>
                        <p>秋田国際人事総研</p>
                      </td>
                    </tr>
                    <tr>
                      <th>顧問税理士</th>
                      <td>
                        <p>久慈会計事務所</p>
                      </td>
                    </tr>
                    <tr>
                      <th>業務内容</th>
                      <td>
                        <p>Webシステム開発</p>
                        <p>システムエンジニアリングサービス</p>
                        <p>検索サイト（Webシステム）構築</p>
                        <p>スマートデバイス向けアプリケーション開発</p>
                        <p>医療系ライブ配信</p>
                        <p>フリーランスエンジニアの求人・案件情報【コラビィ】</p>
                      </td>
                    </tr>
                    <tr>
                      <th>株主</th>
                      <td>
                        <p>吉田 光哉</p>
                        <p>株式会社シグマ・インターナショナル</p>
                      </td>
                    </tr>
                    <tr>
                      <th>認証取得</th>
                      <td>
                        <p>プライバシーマーク付与認定取得（認定番号：第10824551(02)号）</p>
                        <p>労働者派遣事業届出（<a href="/pdf/haken.pdf?20240624" target="_blank" rel="noreferrer" style={{textDecoration: "underline"}}>受理番号：派13-313859</a>）</p>
                        <p>有料職業紹介事業許可番号（13-ユ-310698）</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="sec2" class="companybox clearfix">
                <h3 class="consen">VISION / MISSION</h3>
                <div class="visionTtl">
                  <p class="company-title">代表挨拶</p>
                </div>
                  <p class="mission">社名の、コラボ、すなわちコラボレーション（collaboration）とは、複数の立場や人によって行われる協力・連携・共同作業のことです。そこには「意外な組み合わせ」あるいは「付加価値の創出」というニュアンスが込められます。<br /><br />当社のビジネスはすべての関わる、お客様、技術者、パートナー企業様とのコラボレーションと考えています。積極的に状況を理解し合い、各社、各人のポテンシャルを生かし合うことが、働く人、企業そしてお客様に対してとても重要なことと考えています。<br /><br />また、エンジニアもさまざまな技術を身に付けてきていますし、 技術を習得しようと努力しているエンジニアも数多くいます。そして、その技術を活かすフィールドを求めていることも事実です。出来るだけ多くの企業や人が持続可能な成長をしていくうえで、 今とは違った付加価値の高い技術やサービスを実現出来ればと考えています。</p>
                <div class="visionTtl" style={{marginTop: "40px"}}>
                  <p class="company-title">経営理念</p>
                </div>

                <p class="company-midashi"><AdjustIcon fontSize="small" style={{verticalAlign: "text-bottom"}} />働く人が自分の可能性を追求でき、自己実現の場として利用できる会社</p>
                <div class="visionTtl" style={{marginTop: "40px"}}>
                  <p class="company-title">ミッション</p>
                </div>
                <p class="company-midashi"><AdjustIcon fontSize="small" style={{verticalAlign: "text-bottom"}} />お客様のビジネス革新をITでサポートする</p>
                <p>
                  ビジネスの持続可能な成長のためには、トレンドが目まぐるしく変化するITの更新が必要不可欠です。<br />
                  コラボテクノは、質の高いエンジニアによるスキルと知見を提供し、お客様のビジネス革新を支えています。
                </p>
              </div>
              <div id="sec3" class="companybox clearfix">
                <h3 class="consen">提携会社(グループ会社)</h3>
                <p class="pcfL" style={{paddingBottom: "10px"}}>シグマ・インターナショナル社と資本提携（資本参加＋業務提携）しています。</p>
                <p class="pcfL" style={{paddingBottom: "10px"}}>同社は、Webシステムを中心に受託開発を行う会社ですが、その中でも検索技術を得意分野として、Webサイト、Webアプリケーション開発で、大手ベンダーとの直接取引はもちろんのことユーザー企業との直接取引で業容、業績を伸ばしている会社です。新聞サイト大手「記事検索システム」TＶ局向け「映像素材編集システム」医薬品事業「社内文献検索システム」旅行業「ツアー検索システム」など多くの開発実績があります。 同社のもつ、検索技術を中心としたWebシステムは、今後も必要不可欠な技術と、当社のお客様、また技術者の提供という連携の中で、シナジー効果を期待し、コラボしたい次第です。</p>
                <p class="pcfL bold_link" style={{fontSize: "16px", lineHeight: "1.4"}}><a href="https://www.sigmaint.co.jp" target="_blank" rel="noreferrer"><LinkIcon fontSize="small" style={{verticalAlign: "text-bottom"}} />株式会社シグマ・インターナショナル <span class="moji13px">SIGMA INTERNATIONAL Co.,LTD　</span></a></p>
              </div>
              <div id="sec4" class="companybox pdB60">
                <h3 class="consen">ACCESS</h3>
                <GoogleMap />
                <h4>新橋オフィス</h4>
                <p>〒105-0004 東京都港区新橋3-11-8 オーイズミ新橋第2ビル 901号</p>
              </div>
          </div>
        </article>
      </div>
      <ScrollToTopIcon />
      <Footer />
    </body>
  )
}
