import * as React from 'react';
import { autocompleteClasses, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { colorTheme } from '../const';

export default function ContentsBoxWithSideLine(children) {

  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })

  return (
    <div style={{
      textAlign: 'left',
      justifyContent: 'space-between',
      flexDirection: 'column',
      whiteSpace: 'pre',
      display: isMobileScreen ? 'flex' : children.display,
      width: isMobileScreen ? autocompleteClasses : '40%',
      padding: isMobileScreen ? '5% 11%' : children.padding,
      left: children.left,
      right: children.right
    }}>
      <Typography fontSize={isMobileScreen ? '5.5vw' : '2.3vw'} letterSpacing={isMobileScreen ? '1px' : '3px'} padding={isMobileScreen ? '10px 0 8px 10px' : '2% 0 5% 4%'} borderLeft={isMobileScreen ? 'thick solid #25395E' : 'solid 8px #25395E'} style={{ lineHeight: '1.0em', color: colorTheme.palette.navy.main }} sx={children.titleStyle} fontWeight={'bold'}>
        {children.title}<span style={{ fontSize: isMobileScreen ? '4vw' : '1.5vw' }}>{children.subtitle}</span>
      </Typography>
      <Typography fontSize={isMobileScreen ? '3vw' : '1.4vw'} letterSpacing='1px' sx={children.textStyle} padding={isMobileScreen ? '0px 0px 0px 10px' : '0px 0px 2% 4%'} borderLeft={isMobileScreen ? 'thick solid #25395E' : 'solid 8px #25395E'}>
        {children.text}
      </Typography>
    </div>
  );
}