import { autocompleteClasses, Box, Typography } from '@mui/material';
import * as React from 'react';
import { colorTheme } from '../const';
import ContentsBox from './ContentsBox';
import Image from './Image';
import ButtonCircle from './ButtonCircle';
import Tag from './Tag';
import { useMediaQuery } from 'react-responsive';
import { ThemeProvider } from '@mui/material/styles';
import ScrollToTopIcon from './ScrollToTopIcon';

export default function PersonInterview(props) {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  function displayText(textHead, textBody) {
    if (textHead && textBody) {
      return (
        <ContentsBox title={textHead} text={textBody} titleStyle={{ fontSize: { md: 'inherit', lg:'2vw' } }} />
      )
    } else if (textBody) {
      return (
        <ContentsBox text={textBody} titleStyle={{ padding: '0px' }} />
      )
    } else {
      return null
    }
  }
  function displayTextPc(textHead, textBody) {
    return (
      <div style={{
        textAlign: 'left',
        justifyContent: 'space-between',
        flexDirection: 'column',
        whiteSpace: 'pre-line',
        width: isMobileScreen ? autocompleteClasses : '40%'
      }} >
        {displayText(textHead, textBody)}
      </div>
    )
  }
  function displayImg(img) {
    if (isMobileScreen) {
      return (
        <Box sx={{ padding: '16px 0 48px 0' }}>
          <Image imageSrc={img} width="84%" height="80%" alt="" radius="10px"></Image>
        </Box>
      )
    } else {
      return (
        <div style={{ width: '40%' }}>
          <img src={img} width='100%' alt='' style={{ borderRadius: '10px' }}></img>
        </div>
      )
    }
  }
  // スマホ画面用
  if (isMobileScreen) {
    return (
      <Box className='App'>
        <Box sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}>
          <img src={process.env.PUBLIC_URL + props.imageSrcTop} width='100%' alt='インタビュートップ画像'></img>
          <Box sx={{
            width: autocompleteClasses,
            height: autocompleteClasses,
            position: 'relative',
            whiteSpace: 'pre-line',
            paddingTop: '54px'
          }}>
            <Tag tagStyle={props.tagStyle} tagText={props.tagText} />
            <br />
            <Box sx={{ padding: '35px 45px 10px 40px', textAlign: 'left', color: colorTheme.palette.navy.main }}>
              <Typography variant="h9" sx={{ padding: '0px', textAlign: 'left', fontSize: '13px', fontWeight: 'bold' }}>{props.interviewee}</Typography><br />
              <Typography variant="h5" sx={{ padding: '40px 0px', textAlign: 'left', fontSize: '22px' }} fontWeight={'bold'}>{props.title}</Typography>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: 'white', fontSize: '12px' }}>
            {displayText(null, props.text1)}
            {displayImg(props.imageSrcText1)}
            {displayText(props.text2Head, props.text2Body)}
            {displayImg(props.imageSrcText2)}
            {displayText(props.text3Head, props.text3Body)}
            {displayImg(props.imageSrcText3)}
            {displayText(props.text4Head, props.text4Body)}
            <Box sx={{ paddingTop: '12px' }}>
              <ButtonCircle url={props.nextUrl} text="次のインタビューを読む"></ButtonCircle>
            </Box>
            <Box sx={{ padding: '22px 0 28px 0' }} >
              <ButtonCircle url="/recruit/member" text="一覧表示"></ButtonCircle>
            </Box>
          </Box>
        </Box >
        <ScrollToTopIcon />
      </Box >
    )
  } else {
    return (
      <Box className='App'>
        <Box
          sx={{ backgroundColor: `${colorTheme.palette.creamGray.main}` }}>
          <Box >
            <ThemeProvider theme={colorTheme}>
              <div style={{ position: 'relative', height: props.isConversation ? '5vh' : '15vh', width: '100%', padding: '0px 10px 45% 0px' }}>
                <img src={process.env.PUBLIC_URL + props.imageSrcTop} height='100%' alt='画像' style={{ position: 'absolute', right: 0 }}></img>
                <div style={{
                  display: 'inline-block',
                  width: '35%',
                  minWidth: props.isConversation ? "250px" : '300px',
                  height: '85%',
                  backgroundColor: 'white',
                  position: 'absolute',
                  left: 0,
                  top: 200,
                  borderRadius: '0px 20px 20px 0px',
                  whiteSpace: 'pre-line',
                  margin: '-140px 0 -0px 0',
                }}>
                  <br />
                  <Tag tagStyle={props.tagStyle} tagText={props.tagText} />
                  <br />
                  <Box style={{
                    color: colorTheme.palette.navy.main,
                    padding: props.isConversation ? '5vh 6px 0px 40px' : '10vh 6px 0px 55px',
                    textAlign: 'left'
                  }}>
                    <Typography variant="h9" sx={{ padding: '0px', textAlign: 'left', fontWeight: 'bold', fontSize: props.isConversation ? '1vw' : '1.5vw' }} >{props.interviewee}</Typography><br />
                    <Typography variant="h5" sx={{ padding: '40px 0px', textAlign: 'left', fontWeight: 'bold', fontSize: props.isConversation ? '2.5vw' : '3vw' }} >{props.title}</Typography>
                  </Box>
                </div>
              </div>
            </ThemeProvider>
          </Box>
          <Box sx={{ backgroundColor: 'white' }} fontSize={{ xs: "0.6em", sm: "0.7em", md: "1.1em", xl: "1.2em" }}>
            <div style={{
              display: 'flex', justifyContent: 'space-around'
              , padding: '20px 10px 50px 0px'
            }}>
              {displayImg(props.imageSrcText1)}
              {displayTextPc(null, props.text1)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px 10px 50px 0px' }}>
              {displayTextPc(props.text2Head, props.text2Body)}
              {displayImg(props.imageSrcText2)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px 10px 50px 0px' }}>
              {displayImg(props.imageSrcText3)}
              {displayTextPc(props.text3Head, props.text3Body)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px 10px 50px 0px' }}>
              {displayImg(null)}
              {displayTextPc(props.text4Head, props.text4Body)}
            </div>
            <div style={{ display: 'flex', justifyContent: "space-around", backgroundColor: `${colorTheme.palette.creamGray.main}`, padding: "5% 20%" }}>
              <Box sx={{ width: '50%' }}>
                <ButtonCircle url="/recruit/member" text="一覧表示" color="white" padding='10px 3vw 10px 5%'></ButtonCircle>
              </Box>
              <Box sx={{ width: '50%' }}>
                <ButtonCircle url={props.nextUrl} text="次のインタビューを読む" color="white" padding='10px 3vw 10px 5%'></ButtonCircle>
              </Box>

            </div>
          </Box>
        </Box >
        <ScrollToTopIcon />
      </Box >
    )

  }
}
