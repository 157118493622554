import * as React from 'react';
import { Container } from '@mui/system';

export default function siteLogo(props) {
    return (
        <Container maxWidth="md" className={props.className}>
            <a href='/'>
                <img
                    src={props.imageSrc}
                    width = {props.width}
                    height={props.height}
                    alt={props.alt}
                />
            </a>
        </Container>
    );
  }
