import * as React from 'react';
import '../../../App.css';
import News from '../../../components/corporate/News';
import { Typography } from '@mui/material';

export default function News2(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <News
      title={'弊社サービス名を名乗る「偽サイト」にご注意ください'}
      date={'2024/07/01(月)'}
      text={<Typography variant="body1" style={{ textAlign: 'left', padding: '10px 0' }}>
          最近弊社のサービス（コラビィ）を装った偽サイト（フィッシングサイト）の事例が確認されています。<br />
          このようなサイトにアクセスすると、悪意ある第三者により重要な個人情報が盗まれ、悪用されるおそれがあり大変危険です。<br />
          <br />
          万が一、偽サイトにて個人情報を入力してしまった場合は、最寄りの警察署又はサイバー相談窓口に警察へ通報・相談してください。<br />
          <br />
          今後とも、コラボテクノをご愛顧賜りますよう、よろしくお願い申し上げます。
      </Typography>}
    ></News>
  );
}