import { Box, Typography, Grid, ThemeProvider } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import WorkImg from "../image/web-photo-03.png";
import SesImg from "../image/business_image_01.png";
import WebImg from "../image/business_image_02.png";
import ContentsBoxWithTag from "../components/ContentsBoxWithTag";
import Tag from "../components/Tag";
import ContentsBox from "../components/ContentsBox";
import ScrollToTopIcon from './../components/ScrollToTopIcon';

export default function AboutWork(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box sx={{ backgroundColor: `${colorTheme.palette.creamGray.main}` }}>
        <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
          <ThemeProvider theme={colorTheme}>
            <div style={{ position: 'relative', width: '100%', padding: '0px 10px 45% 0px' }}>
              <img src={process.env.PUBLIC_URL + WorkImg} width='70%' height='100%' alt='トップ画像' style={{ position: 'absolute', right: 0 }}></img>
              <div style={{
                display: 'inline-block',
                width: '35%',
                minWidth: '300px',
                height: '83%',
                backgroundColor: 'white',
                position: 'absolute',
                left: 0,
                top: 170,
                borderRadius: '0px 20px 20px 0px',
                whiteSpace: 'pre-line',
                margin: '-140px 0 -0px 0',
              }}>
                <br />
                <Tag tagStyle={'left'} tagText={"INTERVIEW"} />
                <br />
                <Box style={{
                  padding: '10px 5px 0px 50px',
                  textAlign: 'left'
                }}>
                  <Typography variant="h5" sx={{ padding: '0 0 2% 0', textAlign: 'left', fontWeight: 'bold', fontSize: '2.2vw', color: colorTheme.palette.navy.main }}>
                    {"\nコラボテクノの\n4つの事業"}
                  </Typography>
                  <Typography variant="h9" sx={{ padding: '30px 0px', textAlign: 'left', fontSize: '1vw', lineHeight: '200%' }}>
                    {'コラボテクノは、\nエンジニアのスキル、\nノウハウを生かした、\n４つの事業サービスを\n展開しています。'}
                  </Typography>
                </Box>
              </div>
            </div>
          </ThemeProvider>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
          <img src={process.env.PUBLIC_URL + WorkImg} style={{ width: '100%' }} alt='社員のことTop画像'></img>
        </Box>
      </Box>
      <Box sx={{ display: { xs:"block",sm: "none", md: "none" }, marginTop: "10%", paddingBottom: "5%", color: `${colorTheme.palette.navy.main}` }}>
        <ContentsBoxWithTag
          tagStyle={{ float: "left" }}
          tagText={"INTERVIEW"}
          title={"コラボテクノの\n4つの事業"}
          text={
            "コラボテクノは、エンジニアのスキル、ノウハウを生かした、\n４つの事業サービスを展開しています。"
          }
        ></ContentsBoxWithTag>
      </Box>
      <Box sx={{ position: "absolute", padding: "5% 0 0 0", display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
        <Tag tagStyle={"right"} tagText={"#1 SES"}></Tag>
      </Box>
      <Box sx={{ padding: "5% 0 0 0" }}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <img src={process.env.PUBLIC_URL + SesImg} style={{ width: '80%', padding: '5% 0 0 0' }} alt='社員のことTop画像'></img>
          </Grid>
          <Grid item sm={6} container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Box sx={{ width: "100%", padding: { md: "0 0 0 0", lg: "5% 10% 0 45px", xl: "10% 15% 0 0" }, display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" }, boxSizing: "border-box" }}>
              <Tag tagStyle={"left"} tagText={"#1 SES　　　"}></Tag>
            </Box>
            <Box sx={{ width: "100%", padding: { lg: " 0 0 10% 0", xl: "0 0 15% 0" } }}>
              <ContentsBox
                title={"人とテクノロジーの発想で \n課題解決支援!"}
                text={
                  "お客さま企業に\n人材とスキル・ノウハウを提供する\nシステムエンジニアリングサービス(SES)。\n開発ではオブジェクト指向の開発言語や\nPHPなどのプログラミング。\nインフラではLinuxの設計構築をはじめ、\nAWSの構築支援を行います。"
                }></ContentsBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", padding: "5% 0 0 0", display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
        <Tag tagStyle={"right"} tagText={"#2 Web System"}></Tag>
      </Box>
      <Box sx={{ padding: "3% 0 0 0" }}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <img src={process.env.PUBLIC_URL + WebImg} style={{ width: '80%', padding: '5% 0 0 0' }} alt='社員のことTop画像'></img>
          </Grid>
          <Grid item sm={6} container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Box sx={{ width: "100%", padding: { md: "0 0 0 0", lg: "5% 10% 0 45px", xl: "10% 15% 0 0" }, display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" }, boxSizing: "border-box" }}>
              <Tag tagStyle={"left"} tagText={"#2 Web System"}></Tag>
            </Box>
            <Box sx={{ width: "100%", padding: { lg: " 0 0 10% 0", xl: "0 0 15% 0" } }}>
              <ContentsBox
                title={"調査分析から設計・開発、 \n運用保守までフル対応!"}
                text={
                  "会員管理や調査分析から\n設計・開発、テスト、保守運用まで、\n課題にシームレスに対応。\nECサイト、コンテンツポータルサイト、\n広告配信システムの開発など、\n幅広い分野を担います。"
                }></ContentsBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container Spacing={1} >
        <Grid item sm={6}>
          <Box sx={{ px: { md: 5 }, padding: "5% 0 0 0" }}>
            <ContentsBoxWithTag
              tagStyle={{ float: "left" }}
              tagText={"#3  LIVE Casting"}
              title={"\nクリエイティブチームが\nライブ配信をサポート"}
              text={
                "イベントや講演、教育、後方、採用などの幅広いシーンで、\nライブ配信やオンデマンド配信を実現。クリエイティブチームが\n撮影から現場対応、インターネット配信までトータル的にサポートします。"
              }
            ></ContentsBoxWithTag>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box sx={{ px: { md: 5 }, padding: "5% 0 0 0" }}>
            <ContentsBoxWithTag
              tagStyle={"left"}
              tagText={"#4 Job Change Support"}
              title={"\nフリーランスに特化した\n案件マッチングサービス"}
              text={
                "フリーランスエンジニアに特化した\n案件マッチングサービス「コラビィ」を運営。\n専任のコーディネーターがマッチした案件を提供し、\n契約交渉から案件管理、報酬管理まで全面的にサポートします。\n\n"
              }
            ></ContentsBoxWithTag>
          </Box>
        </Grid>
      </Grid>
      <ScrollToTopIcon />
    </Box>
  );
}
