import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import NewEmployee1Top from '../../../image/interviews/new_employee1_top.png'
import NewEmployee1Text1 from '../../../image/interviews/new_employee1_text1.png'
import NewEmployee1Text2 from '../../../image/interviews/new_employee1_text2.png'
import NewEmployee1Text3 from '../../../image/interviews/new_employee1_text3.png'
import { useMediaQuery } from 'react-responsive';


export default function NewEmployeeInterviews1(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  return (
    <PersonInterview
      nextUrl={"/recruit/member/interview2"}
      tagStyle={{ float: 'left' }}
      tagText={"INTERVIEW"}
      name={"吉牟禮 友秀"}
      entryYear={"2021"}
      title={isMobileScreen ? "エンジニアは、困難を\n乗り越えて出会った、天職。" : "エンジニアは、\n困難を乗り越えて\n出会った、\n天職。"}
      imageSrcTop={NewEmployee1Top}
      text1={"就職を意識し始めた頃にちょうどコロナが流行しだして、大学もその対応に追われていました。講義も休講が続き、あってもたまにオンライン。家から出られない中、このまま大事な時期を無駄に過ごすわけにはいかない、何か自宅で就職につながるスキルを習得できないか、と探して出会ったのがプログラミング、そしてエンジニアという職業でした。\n\nそれまでIT業界やプログラミングにまったく関心はなく、自分でも大胆だなと思いつつ、調べていくうちに、すべてのビジネスの根幹にあるシステムの構築という仕事に俄然興味が湧きました。システム構築に携わることで自分の成長にもつながりそうだと感じて、とにかくやってみようと、オンラインのプログラミングスクールに通い始めました。自分の性に合っていたようで、スクールを修了する頃には、エンジニア職に就いて働く意志が固まっていましたね。"}
      imageSrcText1={NewEmployee1Text1}
      text2Head={"業務は時間内に集中して、\n毎日を楽しむ。"}
      text2Body={"フラットな社風に惹かれてコラボテクノに入社し、実務について約1年です。現在は、携帯電話会社向けの事業支援を行っている会社に常駐し、システムの保守開発を担当しています。たとえば、携帯ショップのスタッフさんがお客さんと話すとき、タブレットを使って説明しますが、そのシステムをエンジニアのチームで保守し、要望を受けて日々、改善やサービスの追加などをしています。\n\nエンジニアというといまだに過酷な現場をイメージされがちですが、実態は大違いで、業務時間は9時～17時30分、お昼休憩1時間で7時間30分。残業はめったにありません。しかも、この現場は基本リモートなので通勤もなく、始業前も終業後も時間のゆとりがあって、自由に楽しく過ごしています。キャリアが浅くても、1日を自分の裁量で使えるのは、この職業の大きな魅力の一つではないでしょうか。"}
      imageSrcText2={NewEmployee1Text2}
      text3Head={"自分の作ったものが役に立つ、\n手応えがある。"}
      text3Body={"エンジニアの魅力は他にも、自分のした仕事がわかりやすく、やりがいがあるところです。やはり自分のした事が何の役に立っているのかに疑問を感じてしまうと、働くモチベーションが保てません。この職業はつくったものがはっきりしているので、自分の成長にも実感が持てるのもいいところですね。\n\n実際に働いてみて少し意外だったのは、コミュニケーションの大切さです。基本は一人で作業するのですが、改善ポイントや作業分担をチームメンバーと話し合って決めていくことが多く、無駄な作業をなくすためにはそれなりのコミュニケーション能力が問われます。これからはプログラミングのスキルはもちろん、コミュニケーション能力や文章力も含めて、総合的にレベルアップして、まずは5年後、プロジェクトリーダーをめざしていきます。こうしたキャリアプランを、自分の力で描けるのも、エンジニアのいいところだと思います。"}
      imageSrcText3={NewEmployee1Text3} />
  );
}