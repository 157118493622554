import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './index.css';
import CorporateTop from './pages/CorporateTop';
import News1 from './pages/corporate/news/News1';
import News2 from './pages/corporate/news/News2';
import Service from './pages/corporate/Service';
import AboutUs from './pages/corporate/AboutUs';
import Contact from './pages/corporate/Contact';
import PrivacyPolicy from './pages/corporate/PrivacyPolicy';
import Security from './pages/corporate/Security';
import Sitemap from './pages/Sitemap';
import RecruitTop from './pages/RecruitTop';
import reportWebVitals from './reportWebVitals';
import NewEmployeeInterview1 from './pages/interviews/new-employee-interviews/NewEmployeeInterviews1';
import NewEmployeeInterview2 from './pages/interviews/new-employee-interviews/NewEmployeeInterviews2';
import NewEmployeeInterview3 from './pages/interviews/new-employee-interviews/NewEmployeeInterviews3';
import JobChangeInterview1 from './pages/interviews/job-change-interviews/JobChangeInterview1';
import JobChangeInterview2 from './pages/interviews/job-change-interviews/JobChangeInterview2';
import WorkLifeBalanceInterview1 from './pages/interviews/work-life-balance-interviews/WorkLifeBalanceInterview1';
import WorkLifeBalanceInterview2 from './pages/interviews/work-life-balance-interviews/WorkLifeBalanceInterview2';
import ConversationInterview1 from './pages/interviews/conversation-interviews/ConversationInterview1';
import AboutCompany from './pages/AboutCompany'
import AboutWork from './pages/AboutWork';
import Employees from './pages/Employees';
import NewGraduateRecruitment from './pages/NewGraduateRecruitment';
import MidcareerRecruitment from './pages/Mid-careerRecruitment';
import Entry from './pages/entry';
import Benefits from './pages/benefits/Benefits';
import { Box } from '@mui/system';
import HeaderBar from './components/HeaderBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    {/* ルーティング設定 */}
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* コーポレート */}
        <Route
          path=''
          element={
            <Box>
              <HeaderBar Menutext={['TOP', 'サービス', '会社情報', '採用情報', 'お問合せ', 'プライバシーポリシー', 'サイトマップ']} MenuLink={["", "service", "about", "recruit", "contact", "privacy", "sitemap"]} />
              <Outlet />
            </Box>
          }
        >
          <Route path='/' element={<CorporateTop />} />
          <Route path='company/news/1' element={<News1 pageTitle={"お知らせ"}/>} />
          <Route path='company/news/2' element={<News2 pageTitle={"お知らせ"}/>} />
          <Route path='service' element={<Service pageTitle={"サービス"}/>} />
          <Route path='about' element={<AboutUs pageTitle={"会社概要"}/>} >
          </Route>
          <Route path='contact' element={<Contact pageTitle={"お問い合わせ"}/>} />
          <Route path='privacy' element={<PrivacyPolicy pageTitle={"個人情報保護方針"}/>} />
          <Route path='security' element={<Security pageTitle={"個人情報の取り扱いについて"}/>} />
          <Route path='sitemap' element={<Sitemap pageTitle={"サイトマップ"}/>} />
        </Route>
        {/* 採用情報 */}
        <Route
          path='/recruit'
          element={
            <Box>
              <HeaderBar Menutext={['採用TOP', '会社のこと', '社員のこと', '仕事のこと', '福利厚生のこと', '新卒採用のこと', '募集・エントリー']} MenuLink={["/recruit", "/recruit/company-info", "/recruit/member", "/recruit/work", "/recruit/benefits", "/recruit/new-graduates", "/recruit/entry"]} isRecruitPage="1" />
              <Outlet />
            </Box>
          }
        >
          <Route path='' element={<RecruitTop pageTitle={"採用情報"}/>} />
          <Route path='company-info' element={<AboutCompany pageTitle={"採用情報"}/>} />
          <Route path='work' element={<AboutWork pageTitle={"採用情報"}/>} />
          <Route path='member' element={<Box><Outlet /></Box>} >
            <Route path='' element={<Box><Employees pageTitle={"採用情報"}/></Box>} />
            <Route path='interview1' element={<NewEmployeeInterview1 pageTitle={"採用情報"}/>} />
            <Route path='interview2' element={<NewEmployeeInterview2 pageTitle={"採用情報"}/>} />
            <Route path='interview3' element={<NewEmployeeInterview3 pageTitle={"採用情報"}/>} />
            <Route path='job-change-interview1' element={<JobChangeInterview1 pageTitle={"採用情報"}/>} />
            <Route path='job-change-interview2' element={<JobChangeInterview2 pageTitle={"採用情報"}/>} />
            <Route path='work-life-interview1' element={<WorkLifeBalanceInterview1 pageTitle={"採用情報"}/>} />
            <Route path='work-life-interview2' element={<WorkLifeBalanceInterview2 pageTitle={"採用情報"}/>} />
            <Route path='discussion' element={<ConversationInterview1 pageTitle={"採用情報"}/>} />
          </Route>
          <Route path='benefits' element={<Benefits pageTitle={"採用情報"}/>} />
          <Route path='new-graduates' element={<Box><Outlet /></Box>}>
            <Route path='' element={<NewGraduateRecruitment pageTitle={"採用情報"}/>}></Route>
          </Route>
          <Route path='mid-career' element={<Box><Outlet /></Box>}>
            <Route path='' element={<MidcareerRecruitment pageTitle={"採用情報"}/>}></Route>
          </Route>
          <Route path='entry' element={<Entry pageTitle={"採用情報"}/>}></Route>
        </Route>
        {/* URLに一致しない場合のルート */}
        <Route path='*' element={<CorporateTop />} />
      </Routes>
    </BrowserRouter>
    <Footer />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
