import { Box, Typography, Grid, ThemeProvider, autocompleteClasses } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import NewGraduateImg from "../image/web-photo-05.png";
import ContentsBox from "../components/ContentsBox";
import ButtonCircle from "../components/ButtonCircle";
import Slider from "../components/Slider";
import humanResourcesImg from "../image/IMG_1989.jpg";
import openPositionsImg from "../image/IMG_2047.png";
import Tag from "../components/Tag";
import Image from "../components/Image";
import ScrollToTopIcon from './../components/ScrollToTopIcon';

export default function NewGraduateRecruitment(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box sx={{ backgroundColor: `${colorTheme.palette.creamGray.main}` }}>
        <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
          <ThemeProvider theme={colorTheme}>
            <div style={{ position: 'relative', width: '100%', padding: '0px 0px 45% 0px' }}>
              <img src={process.env.PUBLIC_URL + NewGraduateImg} width='70%' height='100%' alt='トップ画像' style={{ position: 'absolute', right: 0 }}></img>
              <div style={{
                display: 'inline-block',
                width: '35%',
                minWidth: '250px',
                height: '80%',
                backgroundColor: 'white',
                position: 'absolute',
                left: 0,
                top: 180,
                borderRadius: '0px 20px 20px 0px',
                whiteSpace: 'pre-line',
                margin: '-140px 0 -0px 0',
              }}>
                <br />
                <Tag tagStyle={'left'} tagText={"New Graduates"} />
                <br />
                <Box style={{
                  padding: '15px 5px 0px 50px',
                  textAlign: 'left'
                }}>
                  <Typography variant="h5" sx={{ padding: '0 0 5% 0', textAlign: 'left', fontWeight: 'bold', fontSize: '2.3vw', color: colorTheme.palette.navy.main }}>
                    {"\n新卒採用情報"}
                  </Typography>
                  <Typography variant="h9" sx={{ padding: '40px 0px', textAlign: 'left', fontSize: '1vw', lineHeight: '200%' }}>
                    {'募集職種や採用担当からの\nメッセージなど、\n新卒の方に向けた\n情報をまとめました。'}
                  </Typography>
                </Box>
              </div>
            </div>
          </ThemeProvider>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
          <img src={process.env.PUBLIC_URL + NewGraduateImg} style={{ width: '100%' }} alt='新卒採用Top画像'></img>
        </Box>
      </Box>

    <Box sx={{ width:"100%", backgroundColor: `${colorTheme.palette.creamGray.main}`,display:{ xs: "none", sm: "block", md: "block"},padding:"3% 0 3% 00%" }}>
        <Box >
          <ThemeProvider theme={colorTheme}>
          <Grid container spacing={1}>
          <Grid item sm={3} md={3}>
              <Box
              sx={{ padding: {sm:"7% 0 0 0%",md:"8% 0 0 10%",lg:"2% 0 0 15%",xl:"3% 0 0 15%"}}}
              style={{
              width: '35%',
              minWidth: '310px',
              left: 0,
              position: 'absolute',
              borderRadius: '20px 20px 20px 20px',
              zIndex:1
            }}>
              <Box sx={{ position: "absolute",padding: {sm:'3% 0 0 10%',md:"2% 0 0 7.6%",lg:"3% 0 0 7%"} }}>
                <Tag tagStyle={"left"} tagText={"Massage"}></Tag>
              </Box>
              <img src={process.env.PUBLIC_URL + humanResourcesImg} width="80%" alt="トップサブ画像" style={{ borderRadius: '5%'}}></img>
            </Box>
            </Grid>
            <Grid item sm={9} md={9}>
            <Box style={{ textAlign: 'left', position: 'relative', width: '100%', padding: '5% 0% 2% 0',borderRadius: '20px 0px 0px 20px', backgroundColor: 'white', }}>
              <Typography variant="h5" sx={{ fontSize: { xs: "0.6em", sm: "3vw", md: "1.4em", xl: "2.3vw" } }} style={{ padding: '5% 20% 5% 30%', textAlign: 'left', color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>{"コラボテクノは、「共に社会を創る仲間を求めています。"}</Typography>
              <Typography variant="h6" sx={{ fontSize: { xs: "0.5em", md: "1vw", xl: "2.5em" } }} style={{ padding: '0 0 2% 30%', textAlign: 'left' }} fontWeight={"bold"}>{"新卒採用担当 　熊澤 晴香"}</Typography>
              <Typography variant="h6" sx={{ fontSize: { xs: "0.6em", sm: "1vw", md: "1.0em", xl: "2.2em" } }} style={{ padding: '0 2% 3% 30%', textAlign: 'left' }} fontWeight={"light"}>{"コラボテクノは、「共に社会を創る仲間」を求めています。エンジニアを目指すといっても、コードや言語を綺麗に書くことは手段であっても目的ではありません。\n\nものづくりは、何のために行うのか、その目的を明確に持ち続け、共に会社を成長させていく仲間と一緒に働いて、夢を実現させていきたいと考えています!そんな静かなジョイ熱を燃やし続けるコラボテクノのメンバーと、一緒に成長していきたい。そんな想いに共感する人は、ぜひ一度お話ししましょう!"}</Typography>
              </Box>
            </Grid>
            </Grid>
          </ThemeProvider>
        </Box>
      </Box>
      <ThemeProvider theme={colorTheme}>
        <Box sx={{
          width: autocompleteClasses,
          height: autocompleteClasses,
          backgroundColor: 'inherit',
          whiteSpace: 'pre-line',
          alignItems: "left"
        }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: { xs: "inline-block", sm: "none", md: "none", xl: "none" }, padding: '5% 0 0 0' }}>
              <Box sx={{ position: "absolute" }}>
                <Tag tagStyle={"left"} tagText={"Massage"}></Tag>
              </Box>
              <Image imageSrc={humanResourcesImg} width="100%" alt=""></Image>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }} style={{ padding: '10% 0 0 0', textAlign: 'left' }}>
              <Typography variant="h2" sx={{ fontSize: { xs: "1em", sm: "1em" } }} style={{ padding: '0px 0px 5% 5%', color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>{"コラボテクノは、\n「共に社会を創る仲間」を\n求めています。"}</Typography>
              <Typography variant="h5" sx={{ fontSize: { xs: "0.8em", sm: "1em" } }} style={{ padding: '0px 0px 5% 5%' }} >{"新卒採用担当 　熊澤 晴香"}</Typography>
              <Typography variant="h2" sx={{ width: "85%", fontSize: { xs: "0.7em", sm: "0.7em" } }} style={{ padding: '0px 2% 5% 5%' }} fontWeight={"weight "}>{"ものづくりは、何のために行うのか、その目的を明確に持ち続け、共に会社を成長させていく仲間と一緒に働いて、夢を実現させていきたいと考えています！そんな静かな情熱を燃やし続けるコラボテクノのメンバーと、一緒に成長していきたい。そんな思いに共感する人は、ぜひ一度お話しましょう！"}</Typography>
            </Box>
            <Box sx={{ padding: " 5% 0 0 0" }}>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      <Box>
        <ContentsBox
          title={"新卒入社で活躍する社員の声"}
          text={
            "新卒で入社した先輩社員に、\n仕事のことや就活のことをインタビューしました。"
          }
        ></ContentsBox>
      </Box>
      <Box sx={{ padding: "5% 5% 5% 5%" }}>
        <Slider category="newEmployee" slidesToShow={3}></Slider>
      </Box>
      <Box sx={{ marginTop: "10%" }}>
        <img src={process.env.PUBLIC_URL + openPositionsImg} width="100%" alt="募集職種画像"></img>
        <ContentsBox
          title={"募集職種"}
          text={
            "コラボテクノのエンジニアとして自分の足で\n歩もうとする新卒の方のentryをお待ちしています。"
          }
        ></ContentsBox>
        <Box sx={{ paddingTop: "5%", paddingBottom: "5%" }}>
          <ButtonCircle
            url="/recruit/entry#new-graduates"
            text="エントリー"
          ></ButtonCircle>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
