import * as React from 'react';
import '../App.css';
import '../pages-css/corporateTop.css';
import '../pages-css/corporate.css';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import frontCopy from '../image/company/front-mob-copy.png';
import frontCopyPc from '../image/company/front-copy.png';
import Footer from './corporate/footer';
import LogoImg from '../image/company/logo.png';
import RecruitImg from '../image/company/recruit-txt.png';
import Nav1 from '../image/company/nav1.png';
import Nav2 from '../image/company/nav2.png';
import Nav3 from '../image/company/nav3.png';
import ScrollToTopIcon from './../components/ScrollToTopIcon';

function CorporateTop() {
  React.useEffect(() => {
      document.title = `コラボテクノ株式会社`;
  });
  function displayImg(img, alt) {
    return (
        <Container maxWidth="lg">
            <img
                src={img}
                width="100%"
                height="100%"
                alt={alt}
            >
            </img>
        </Container>
    )
  };

  return (
    <div>
      <div className='App'>
      <header id="header" className="top-header">
        <div id="top" className="header-inner">
          <div className="sub_nav top_nav responsiveText">
            <Link to="/service">SERVICE</Link> / <Link to="/about">ABOUT US</Link> / <Link to="/recruit">RECRUIT</Link> / <Link to="/contact">CONTACT</Link>
          </div>
        </div>
      </header>
        <body>
          <div className="mainbox top-image">
            <div className="innerbox">
              <Link to="/" id="topLogo"><Box sx={{ padding: '16px 0 48px 0' }}>{displayImg(LogoImg)}</Box></Link>
              <img className="top-copy" id="frontCopy" src={frontCopy} alt="発想力で、新たな価値を創造する。"></img>
              <img className="top-copy" id="frontCopyPc" src={frontCopyPc} alt="発想力で、新たな価値を創造する。"></img>
            </div>
          </div>
          <div className="clearfix textC">
            <div id="jsRecruit" className="top-recruit">
              <Link to="/recruit">{displayImg(RecruitImg, "企業ロゴ")}</Link>
            </div>
          </div>
          <Container maxWidth="lg" className="newsArea">
            <div>
              <Typography variant="h5" style={{ padding: '10px 0' }}>NEWS</Typography>
              <ul>
                <li><Link to="company/news/1">ホームページをリニューアルしました！(2023/7/9)</Link></li>
                <li><Link to="company/news/2">弊社サービス名を名乗る「偽サイト」にご注意ください(2024/7/1)</Link></li>
              </ul>
            </div>
          </Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Container maxWidth="lg" sx={{ backgroundColor: '#ededed', textAlign: 'center'}}>
                  <div className="top-mainbox left">
                    <p className="icon">
                      <Link to="/service"></Link>
                      <img src={Nav2} alt="icon"></img>
                    </p>
                    <h3><Link to="/service" className="corporate-top-link">サービス</Link></h3>
                    <ul>
                      <li><Button text="Webシステム開発" url="/service#sec1"></Button></li>
                      <li><Button text={"システムエンジニアリングサービス（SES）"} url="/service#sec2"></Button></li>
                      <li><Button text="開発領域" url="/service#sec3"></Button></li>
                      <li><Button text="プロジェクト事例" url="/service#sec4"></Button></li>
                      <li><Button text="フリーランスエンジニアの求人・案件情報【コラビィ】" url="/service#sec5"></Button></li>
                    </ul>
                  </div>
                </Container>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Container maxWidth="lg" sx={{textAlign: 'center'}}>
                  <div className="top-mainbox center">
                    <p className="icon">
                      <Link to="/about"></Link>
                      <img src={Nav3} alt="icon"></img>
                    </p>
                    <h3><Link to="/about" className="corporate-top-link">会社情報</Link></h3>
                    <div className="menu-sidenav02-container">
                      <ul>
                      <li><Button text="会社概要" url="/about"></Button></li>
                      <li><Button text="ビジョン・ミッション" url="/about#sec2"></Button></li>
                      <li><Button text="提携会社(グループ会社)" url="/about#sec3"></Button></li>
                      <li><Button text="アクセス" url="/about#sec4"></Button></li>
                      </ul>
                    </div>
                  </div>
                </Container>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Container maxWidth="lg" sx={{ backgroundColor: '#ededed', textAlign: 'center'}}>
                  <div className="top-mainbox right">
                    <p className="icon">
                      <Link to="/recruit"></Link>
                      <img src={Nav1} alt="icon"></img>
                    </p>
                    <h3><Link to="/recruit" className="corporate-top-link">採用情報</Link></h3>
                    <div className="menu-sidenav02-container">
                      <ul>
                        <li><Button text="採用コンセプト" url="/recruit"></Button></li>
                        <li><Button text="新卒採用" url="/recruit/entry#new-graduates"></Button></li>
                        <li><Button text="中途採用" url="/recruit/entry#mid-career"></Button></li>
                        <li><Button text="開発パートナー募集" url="/contact"></Button></li>
                      </ul>
                    </div>
                  </div>
                </Container>
              </Grid>
            </Grid>
          </Box>
          <ScrollToTopIcon />
          <Footer />
        </body>
      </div>
    </div >
  );
}

export default CorporateTop;