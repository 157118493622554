import * as React from 'react';
import { autocompleteClasses, Box, Typography } from '@mui/material';

export default function ContentsBox(children) {

  return (
    <Box sx={{
      width: autocompleteClasses,
      height: autocompleteClasses,
      backgroundColor: 'inherit',
      position: 'relative',
      whiteSpace: 'pre-line'
    }}>
      <Box style={{
        padding: children.isZeroPadding ? '0px' : '15px 45px 15px 45px',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}>
        <Typography  fontSize={{ xs: "20px", sm: "20px", md: "30px", lg:"30px", xl: "2vw" }} style={{ padding: children.title ? "2% 0 2% 0" : '0px' }} sx={children.titleStyle} fontWeight={'bold'}>
          {children.title}
        </Typography>
        <Typography fontSize={{ xs: "12px", sm: "14px", md: "20px",lg:"25px", xl: "1.2vw" }} sx={children.textStyle} >
          {children.text}
        </Typography>
      </Box>
    </Box>
  );
}
