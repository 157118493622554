import * as React from 'react';
import Slider from "react-slick";
import newEmployeeImg1 from "../image/new-employee-01.jpg";
import newEmployeeImg2 from "../image/new-employee-02.jpg";
import newEmployeeImg3 from "../image/new-employee-03.jpg";
import jobChangeImg1 from "../image/job-change-01.jpg";
import jobChangeImg2 from "../image/job-change-02.jpg";
import lifeWorkValanceImg1 from "../image/lifework-valance-01.jpg";
import lifeWorkValanceImg2 from "../image/lifework-valance-02.jpg";
import '../components-css/Slider.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// 引数でカテゴリを指定（props.category）
// - memberAll: 全メンバー
// - newEmployee: 新卒採用
// - jobChange: 異業種転職
// - lifeWorkValance: ライフワークバランス
export default function ImageSlider(props) {

  const newEmployeeArray = [
    {profile: "吉牟禮 友秀 システムエンジニア 2021年入社", title: "エンジニアは、困難を\n乗り越えて出会った、天職。", img: newEmployeeImg1, link: "/recruit/member/interview1"},
    {profile: "佐々木 誠 システムエンジニア 2022年入社", title: "好きなゲームづくりから、\n世の中を動かすものづくりへ。", img: newEmployeeImg2, link: "/recruit/member/interview2"},
    {profile: "田本 和之 システムエンジニア 2022年入社", title: "先生はYoutube、\n就活中の急転チャレンジ。", img: newEmployeeImg3, link: "/recruit/member/interview3"}
  ];

  const jobChangeArray = [
    {profile: "齋藤 祐太 システムエンジニア 2019年入社", title: "知人の後押しで、書店員から\nプログラミングの世界へ。", img: jobChangeImg1, link: "/recruit/member/job-change-interview1"},
    {profile: "野原 僚香 システムエンジニア 2021年入社", title: "好きなゲームづくりから、\n世の中を動かすものづくりへ。", img: jobChangeImg2, link: "/recruit/member/job-change-interview2"},
  ];

  const lifeWorkValanceArray = [
    {profile: "小幡 琴美 システムエンジニア 2021年入社", title: '結婚したことで、\n自分の仕事力がアップ。', img: lifeWorkValanceImg1, link: "/recruit/member/work-life-interview1"},
    {profile: "山本 高志 システムエンジニア 2016年入社", title: "まるまる１ヶ月間、\n\"育業\"の現場にシフト。", img: lifeWorkValanceImg2, link: "/recruit/member/work-life-interview2"},
  ];

  const memberAllArray = [...newEmployeeArray, ...jobChangeArray, ...lifeWorkValanceArray];

  const settings = {
    slidesToShow: props.slidesToShow,
    dots: false,
    responsive: [
      {
        //スマホ表示(幅480px以下の画面)
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          swipe: true,
          infinite: true,
          autoplay: true,
          centerMode: true,
          centerPadding: "15%"
        }
      },
    ]
  };

  if (props.category === 'memberAll') {
    arrayShuffle(memberAllArray);
    return (
      <Slider {...settings} className="sliderWrapper">
        {memberAllArray.slice(0, 3).map((memberAll, index) => {
          return (
            <div key={index}>
              <div className='slideStyle'>
              <a href={memberAll.link}><img className='imgStyle' src={process.env.PUBLIC_URL+memberAll.img} alt=""/></a>
                <p className='profileStyle'>{memberAll.profile}</p>
                <p className='titleStyle'>{memberAll.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}</p>
              </div>
            </div>
          )
        })}
      </Slider>
    );
  } else if (props.category === 'newEmployee') {
    return (
      <Slider {...settings} className="sliderWrapper">
        {newEmployeeArray.map((newEmployee, index) => {
          return (
            <div key={index}>
              <div className='slideStyle'>
                <a href={newEmployee.link}><img className='imgStyle' src={process.env.PUBLIC_URL+newEmployee.img} alt=""/></a>
                <p className='profileStyle'>{newEmployee.profile}</p>
                <p className='titleStyle'>{newEmployee.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}</p>
              </div>
            </div>
          )
        })}
      </Slider>
    );
  } else if (props.category === 'jobChange') {
    return (
      <Slider {...settings} className="sliderWrapper">
        {jobChangeArray.map((jobChange, index) => {
          return (
            <div key={index}>
              <div className='slideStyle'>
                <a href={jobChange.link}><img className='imgStyle' src={process.env.PUBLIC_URL+jobChange.img} alt=""/></a>
                <p className='profileStyle'>{jobChange.profile}</p>
                <p className='titleStyle'>{jobChange.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}</p>
              </div>
            </div>
          )
        })}
      </Slider>
    );
  } else if (props.category === 'lifeWorkValance') {
    return (
      <Slider {...settings} className="sliderWrapper">
        {lifeWorkValanceArray.map((lifeWorkValance, index) => {
          return (
            <div key={index}>
              <div className='slideStyle'>
                <a href={lifeWorkValance.link}><img className='imgStyle' src={process.env.PUBLIC_URL+lifeWorkValance.img} alt=""/></a>
                <p className='profileStyle'>{lifeWorkValance.profile}</p>
                <p className='titleStyle'>{lifeWorkValance.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}</p>
              </div>
            </div>
          )
        })}
      </Slider>
    );
  }

  // 配列の中身をシャッフルする
  function arrayShuffle(array) {
    for(let i = (array.length - 1); 0 < i; i--){

      let r = Math.floor(Math.random() * (i + 1));
      let tmp = array[i];

      array[i] = array[r];
      array[r] = tmp;
    }
    return array;
  }
}
