import * as React from 'react';
import { colorTheme } from '../../../src/const';
import { Box, Typography } from '@mui/material';
import Footer from '../../pages/corporate/footer';
import ScrollToTopIcon from '../ScrollToTopIcon';

export default function News(props) {
  React.useEffect(() => {
    document.title = `ニュース｜コラボテクノ株式会社`;
  });
  return (
    <body>
      <Box className='App' sx={{backgroundColor:`${colorTheme.palette.creamGray.main}`, borderTop: '1px solid #e5e5e5'}}>
        <Box sx={{maxWidth: '80vw', margin: 'auto'}}>
          <Box sx={{backgroundColor: 'white', padding : '5% 8%', minHeight: '400px'}}>
            <Typography variant="h4" style={{ padding: '10px 0' }}>{props.title}</Typography>
            <Typography style={{ textAlign: 'right', padding: '10px 0' }}>{props.date}</Typography>
            {props.text}
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
      <Footer />
    </body>
  )
}
